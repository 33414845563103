<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="title">
        <slot name="title" />
      </template>
      <template slot="actions">
        <template v-if="selectedOrdersType !== 'all' && selectedOrders.length">
          <div class="is-flex is-align-center">
            <span class="font-base">{{ selectedOrdersLabel }} </span>
            <el-button
              class="ml-10"
              type="plain"
              @click="showProvisionedOrdersConfirm = true"
              >Confirm Orders</el-button
            >
          </div>
        </template>
        <template v-else>
          <el-dropdown
            v-if="service == 'meal'"
            @command="
              menu = $event;
              $emit('filter', $event);
            "
          >
            <el-button type="plain" :disabled="loading">
              {{ menus[menu] }} <i class="eden-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(value, key, i) in menus"
                :key="i"
                :class="{ selected: key === menu }"
                :command="key"
                >{{ value }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown @command="command">
            <el-button type="plain" :disabled="loading">
              {{ views[view] }} <i class="eden-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(value, key, i) in views"
                :key="i"
                :class="{ selected: key === view }"
                :command="key"
                >{{ value }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            type="plain"
            icon="eden-icon-upload"
            class="ml-10"
            :disabled="disableExport"
            @click="showProvisionedOrdersExport = true"
          >
            Export
          </el-button>
        </template>
      </template>
    </eden-table-actions>
    <p v-if="loading">Loading</p>
    <component
      v-else
      :is="`${view}-orders-view`"
      :service="service"
      :orders="activeOrders"
      :period="period"
      @confirm="confirm"
    />
    <provisioned-orders-export
      :show.sync="showProvisionedOrdersExport"
      :period="period"
      :service="service"
      :data="activeOrders"
      :data-time="ordersTime"
    />
    <provisioned-orders-confirm
      ref="confirmOrders"
      :show.sync="showProvisionedOrdersConfirm"
      :selected-orders="selectedOrders"
      @confirmed="$emit('confirmed')"
    />
  </div>
</template>

<script>
import AllOrdersView from "./Views/AllOrdersView";
import SpOrdersView from "./Views/SpOrdersView";
import GardenerOrdersView from "./Views/GardenerOrdersView";
import ProvisionedOrdersExport from "@/components/ServiceOperations/ProvisionedOrders/ProvisionedOrdersExport";
import ProvisionedOrdersConfirm from "@/components/ServiceOperations/ProvisionedOrders/ProvisionedOrdersConfirm";
import ProvisionedOrdersMealFilter from "@/components/ServiceOperations/ProvisionedOrders/Food/ProvisionedOrdersMealFilter";

export default {
  name: "ProvisionedOrdersView",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    service: {
      type: String,
      default: "",
    },
    period: {
      type: String,
      default: "",
    },
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
    ordersTime: {
      type: [Date, String],
      default: "",
    },
  },
  components: {
    ProvisionedOrdersConfirm,
    ProvisionedOrdersExport,
    ProvisionedOrdersMealFilter,
    AllOrdersView,
    SpOrdersView,
    GardenerOrdersView,
  },
  data() {
    return {
      view: "all",
      views: {
        all: "All Orders View",
        sp: "SP View",
        gardener: "Gardeners View",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      menu: "all",
      menus: {
        "all": "All Menu orders",
        "individual-standard": "Individual Standard Menu orders",
        "corporate-standard": "Corporate Standard Menu orders",
        "lite": "Lite Menu orders",
        "basic": "Basic Menu orders",
      },
      selectedOrdersType: "all",
      selectedOrders: [],
      showProvisionedOrdersConfirm: false,
      showProvisionedOrdersExport: false,
    };
  },
  computed: {
    activeOrders() {
      return this.orders;
      // .filter((item) => item.customer_active);
    },
    title() {
      return `${this.activeOrders.length} Orders`;
    },
    selectedOrdersLabel() {
      const orders = this.selectedOrders.length;
      return `${this.formatServiceUnit("order", orders, true)} selected`;
    },
    disableExport() {
      return this.loading || !this.activeOrders.length;
    },
  },
  methods: {
    command(view) {
      this.view = view;
    },
    confirm({ type, ids }) {
      this.selectedOrdersType = type;
      this.selectedOrders = ids;

      if (type === "all") {
        this.showProvisionedOrdersConfirm = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
