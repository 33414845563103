<template>
  <eden-confirm-dialog
    title="Confirm Orders"
    button-type="success"
    button-text="Confirm"
    :button-status="loading"
    :show.sync="setShow"
    @confirm="confirm"
  >
    <p>
      Are you sure you want to confirm all
      <span>{{ selectedOrders.length }}</span> orders?
    </p>
  </eden-confirm-dialog>
</template>

<script>
import provisionedorders from "@/requests/serviceoperations/provisionedorders";

export default {
  name: "ProvisionedOrdersConfirm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedOrders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      showConfirmOrders: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    confirm() {
      this.loading = true;
      const payload = {
        order_ids: this.selectedOrders,
      };
      provisionedorders
        .confirm(payload)
        .then((response) => {
          this.loading = false;

          if (response.data.status) {
            this.setShow = false;
            this.$message.success(response.data.message);
            this.$emit("confirmed");
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss"></style>
