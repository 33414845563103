<template>
  <div>
    <el-table :data="Object.keys(gardenerOrders)" :show-header="false">
      <!-- <el-table-column
        v-if="allowAccessFor(['superadmin', 'operations', 'admin'])"
        width="80"
      >
        <template slot-scope="scope">
          <div class="is-flex is-align-center">
            <el-button
              type="text"
              icon="eden-icon--download"
              class="mr-1"
              @click="setGardener(scope.row, 'download')"
              :disabled="gardenerOrders[scope.row].confirmed < 1"
            ></el-button>
            <el-button
              v-if="canPrintOrders"
              type="text"
              icon="el-icon-printer"
              class="mr-1"
              @click="setGardener(scope.row, 'print')"
            ></el-button>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column width="200">
        <template slot-scope="scope">
          <p class="text-faded">
            {{ scope.row }}
          </p>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="is-flex is-align-center">
            <el-tag
              v-if="gardenerOrders[scope.row].confirmed"
              class="eden-tag"
              type="success"
              >{{ gardenerOrders[scope.row].confirmed }} confirmed
              orders</el-tag
            >
            <el-tag
              v-if="gardenerOrders[scope.row].edited"
              class="eden-tag"
              type="warning"
              >{{ gardenerOrders[scope.row].edited }} edited orders</el-tag
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column width="100" type="expand" align="right">
        <template slot-scope="scope">
          <el-table
            :data="gardenerOrders[scope.row].orders"
            class="table-align-vertical"
          >
            <el-table-column prop="order_id" width="120">
              <template slot="header">
                <span class="eden-table-header"> Order Id </span>
              </template>
              <template slot-scope="scope">
                <div class="order">
                  <span
                    v-if="scope.row.completed_status === 'incomplete'"
                    class="indicator"
                  ></span>
                  <router-link
                    :to="{
                      name: 'orders.order',
                      params: { id: scope.row.id },
                    }"
                  >
                    <span class="font-sm text-primary">{{ scope.row.id }}</span>
                  </router-link>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <span class="eden-table-header"> Customer </span>
              </template>
              <template slot-scope="scope">
                <p
                  v-if="!scope.row.customer_active_status"
                  class="text-inactive"
                >
                  <router-link
                    :to="{
                      name: 'customers.individual',
                      params: { id: scope.row.user_id },
                    }"
                    >{{ formatName(scope.row.customer) }}</router-link
                  >
                </p>
                <p v-else class="text-faded">
                  <router-link
                    :to="{
                      name: 'customers.individual',
                      params: { id: scope.row.user_id },
                    }"
                    >{{ formatName(scope.row.customer) }}</router-link
                  >
                </p>
              </template>
            </el-table-column>
            <el-table-column width="160">
              <template slot="header">
                <span class="eden-table-header"> Service Partner </span>
              </template>
              <template slot-scope="scope">
                <p class="text-faded">
                  {{ scope.row.sp }}
                </p>
              </template>
            </el-table-column>
            <el-table-column v-if="servicesWithQuantity.includes(service)">
              <template slot="header">
                <span class="eden-table-header"> Quantity </span>
              </template>
              <template slot-scope="scope">
                <p class="text-faded">
                  {{ scope.row.quantity }}
                  {{ service === "laundry" ? "bag(s)" : "room(s)" }}
                </p>
              </template>
            </el-table-column>
            <el-table-column v-if="service.includes('meal')" width="300">
              <template slot="header">
                <span class="eden-table-header"> Items </span>
              </template>
              <template slot-scope="scope">
                <p
                  v-for="(item, i) in scope.row.meals"
                  :key="i"
                  class="text-faded mb-1"
                >
                  {{ formatMealName(item) }}
                </p>
              </template>
            </el-table-column>
            <el-table-column v-else-if="service === 'beauty'" width="300">
              <template slot="header">
                <span class="eden-table-header"> Items </span>
              </template>
              <template slot-scope="scope">
                <div
                  v-for="(item, i) in scope.row.items_assigned_to_order"
                  :key="i"
                  class="eden-td-stack mb-sm-1"
                >
                  <p class="text-black">
                    {{ formatToTitleCase(item.variation) }}
                  </p>
                  <span>{{ formatToTitleCase(item.addons) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-else width="150">
              <template slot="header">
                <span class="eden-table-header"> Items </span>
              </template>
              <template slot-scope="scope">
                <p
                  v-for="(item, i) in scope.row.items_assigned_to_order"
                  :key="i"
                  class="text-faded mb-1"
                >
                  {{ formatMealTitle(item.food) }}
                </p>
              </template>
            </el-table-column>
            <el-table-column v-if="service === 'laundry'" width="120">
              <template slot="header">
                <span class="eden-table-header"> PickUp </span>
              </template>
              <template slot-scope="scope">
                <p class="text-faded">
                  {{ formatDate(scope.row.service_pickup_date, "d m,y") }}
                </p>
              </template>
            </el-table-column>
            <el-table-column width="120">
              <template slot="header">
                <span class="eden-table-header"> Delivery </span>
              </template>
              <template slot-scope="scope">
                <p class="text-faded">
                  {{ formatDate(scope.row.delivery, "d m,y") }}
                </p>
              </template>
            </el-table-column>
            <el-table-column width="140">
              <template slot="header">
                <div class="table--header">
                  <span>Order Type</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <eden-tag
                  :value="
                    scope.row.is_one_time_order ? 'One-time' : 'Subscription'
                  "
                />
              </template>
            </el-table-column>
            <el-table-column width="120">
              <template slot="header">
                <span class="eden-table-header"> Status </span>
              </template>
              <template slot-scope="scope">
                <el-tag :type="scope.row.confirmed ? 'success' : 'warning'">{{
                  scope.row.confirmed ? "Confirmed" : "Edited"
                }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
    </el-table>
    <!-- <provision-orders-download
      :show.sync="showProvisionedOrdersDownload"
      :orders="gardenerOrders"
      :name="gardenerName"
      :service="service"
      :period="period"
    />
    <provision-orders-print
      :show.sync="showProvisionedOrdersPrint"
      :gardener-orders="gardenerOrders[gardenerName]"
      :name="gardenerName"
    /> -->
  </div>
</template>

<script>
// import ProvisionOrdersDownload from "./ProvisionedOrdersDownload";
// import ProvisionOrdersPrint from "@/components/ServicePartners/ProvisionedOrders/ProvisionedOrdersPrint";
export default {
  name: "GardenerOrdersView",
  // components: { ProvisionOrdersPrint, ProvisionOrdersDownload },
  props: {
    service: {
      type: String,
      default: "",
    },
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedOrders: {
      type: Array,
      default() {
        return [];
      },
    },
    //
    // provisionedOrders: {
    //   type: Array,
    //   required: true
    // },
    period: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      gardenerName: "",
      showProvisionedOrdersDownload: false,
      showProvisionedOrdersPrint: false,
      servicesWithQuantity: ["cleaning", "laundry"],
    };
  },
  computed: {
    canPrintOrders() {
      return (
        this.service === "meal" &&
        (this.period === "today" || this.period === "tomorrow")
      );
    },
    gardenerOrders() {
      const orders = {};
      // this.orders.forEach((order, index) => {
      // const spName = Object.keys(this.orders[index]);
      this.orders.forEach((order) => {
        if (!orders[order.gardener]) {
          orders[order.gardener] = {
            orders: [],
            confirmed: 0,
            edited: 0,
          };
        }
        const newOrder = orders[order.gardener].orders;
        newOrder.push(order);
        // newOrder[newOrder.length - 1].sp = spName;
        if (order.confirmed) {
          orders[order.gardener].confirmed += 1;
        } else {
          orders[order.gardener].edited += 1;
        }
      });
      // });
      return orders;
    },
  },
  methods: {
    setGardener(gardenerName, action) {
      this.gardenerName = gardenerName;
      if (action === "download") {
        this.showProvisionedOrdersDownload = true;
      } else {
        this.showProvisionedOrdersPrint = true;
      }
    },
  },
};
</script>

<style lang="scss">
.order {
  display: flex;
  align-items: center;

  .indicator {
    height: 8px;
    width: 8px;
    border-radius: 100px;
    margin-right: 16px;
    background: #ff9d00;
  }
}
</style>
