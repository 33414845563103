<template>
  <el-collapse v-model="sp" accordion class="sp-view--collapse">
    <template v-for="(value, key, index) in sortedOrders">
      <el-collapse-item v-if="value.orders.length" :key="index" :name="key">
        <template slot="title">
          <div class="title">
            <div class="name">
              <i :class="`el-icon-caret-${sp === key ? 'bottom' : 'right'}`" />
              <span>{{ key }}</span>
            </div>
            <div class="is-flex is-align-center">
              <el-tag type="success">{{ value.confirmed }} confirmed</el-tag>
              <el-tag type="warning">{{ value.edited }} edited</el-tag>
              <el-dropdown class="ml-10" @command="command">
                <span class="el-dropdown-link">
                  <i class="eden-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="key"
                    >Confirm all orders</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </template>
        <all-orders-view
          :service="service"
          :orders="value.orders"
          @confirm="confirm($event)"
        />
      </el-collapse-item>
    </template>
  </el-collapse>
</template>

<script>
import AllOrdersView from "@/components/ServiceOperations/ProvisionedOrders/Views/AllOrdersView";
export default {
  name: "SpView",
  components: { AllOrdersView },
  props: {
    service: {
      type: String,
      default: "",
    },
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedOrders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      sp: "",
    };
  },
  computed: {
    sortedOrders() {
      let sps = {};
      this.orders.forEach((order) => {
        if (!sps[order.sp]) {
          sps[order.sp] = {
            confirmed: 0,
            edited: 0,
            orders: [],
          };
        }

        order.confirmed ? sps[order.sp].confirmed++ : sps[order.sp].edited++;
        sps[order.sp].orders.push(order);
      });

      return sps;
    },
    setSelectedOrders: {
      get() {
        return this.selectedOrders;
      },
      set(value) {
        this.$emit("update:selectedOrders", value);
      },
    },
  },
  methods: {
    setSelections(orders) {
      this.setSelectedOrders = orders.map((order) => order.id);
    },
    confirm(event) {
      this.$emit("confirm", event);
    },
    command(sp) {
      this.$emit("confirm", {
        type: "all",
        ids: this.sortedOrders[sp].orders.map((order) => order.id),
      });
    },
  },
};
</script>

<style lang="scss">
.sp-view--collapse {
  .el-collapse-item {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--eden-grey-septenary);
    padding: 16px 25px;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .name {
        i {
          margin-right: 10px;
        }

        span {
          color: var(--eden-grey-primary);
        }
      }
    }

    .el-collapse-item__arrow {
      display: none;
    }

    .el-collapse-item__wrap {
      margin-top: 20px;
    }
  }
}
</style>
