const parseOrder = (order, sp, service) => {
  let items = order.items_assigned_to_order;
  let parsedOrder = {
    id: order.order_id,
    complete: order.completed_status !== "incomplete",
    customer: order.customer ? order.customer.toLowerCase() : "No Name",
    customer_id: order.user_id,
    customer_active: order.customer_active_status,
    gardener:
      typeof order.gardener == "object" ? order.gardener?.name : order.gardener,
    confirmed: !!order.is_confirmed,
    delivery: order.service_delivery_date,
    is_one_time_order: order.is_one_time_order,
    menu_type: order.menu_type,
    pickup: order.service_pickup_date || null,
    sp,
  };

  switch (service) {
    case "beauty":
      parsedOrder.variations = items.map((item) => {
        return {
          name: item.variation,
          addons: item.addons,
        };
      });
      parsedOrder.type = "";
      parsedOrder.preference =
        order.order_id % 2 == 0
          ? "This is a very long beauty preference note where the customer talks about things they want/don’t want."
          : "This is a short beauty preference note.";
      break;
    case "cleaning":
      parsedOrder.type = items[0].food;
      parsedOrder.rooms = 0;
      parsedOrder.preference =
        order.order_id % 2 == 0
          ? "This is a very long cleaning preference note where the customer talks about things they want/don’t want."
          : "This is a short cleaning preference note.";
      break;
    case "laundry":
      parsedOrder.weight = order.weight || 0;
      parsedOrder.type = items[0].food;
      parsedOrder.items = order.tally || 0;
      parsedOrder.bags = order.laundry_quantity;
      parsedOrder.preference =
        order.laundry_quantity % 2 == 0
          ? "I want all starchable clothes starched. I also want all the clothes that should be hung, hung."
          : "This is a short laundry preference note.";
      break;
    case "meal":
      parsedOrder.meals = items.map((item) => item.food);
      break;
    default:
      break;
  }

  return parsedOrder;
};

const parseSpOrders = (orders, sp, service) => {
  let confirmed = 0;
  let edited = 0;
  let complete = 0;
  let incomplete = 0;
  let customers = [];

  /**
   * Laundry
   */
  let types = {};
  let weight = 0;

  /**
   * Meal
   */
  let meals = 0;

  orders.forEach((order) => {
    order.confirmed ? confirmed++ : edited++;
    order.complete ? complete++ : incomplete++;

    if (!customers.includes(order.customer_id)) {
      customers.push(order.customer_id);
    }

    if (service === "meal") {
      meals = meals + (order.meals ? order.meals.length : 0);
    }

    if (service === "laundry") {
      weight = weight + order.weight || 0;
      if (!types[order.type]) {
        types[order.type] = {
          total: 0,
          items: 0,
          bags: 0,
        };
      }
      types[order.type].total = types[order.type].total + 1;
      types[order.type].items = types[order.type].items + order.items;
      types[order.type].bags = types[order.type].bags + order.bags;
    }
  });

  let parsedOrders = {
    customers: [...new Set(customers)],
    orders,
    confirmed,
    edited,
    complete,
    incomplete,
  };

  switch (service) {
    case "laundry":
      parsedOrders["types"] = types;
      parsedOrders["weight"] = weight;
      break;
    case "meal":
      parsedOrders[sp.toLowerCase().includes("juice") ? "juices" : "meals"] =
        meals;
      break;
    default:
      break;
  }

  return parsedOrders;
};

export const parseProvisionedOrders = (provisionedOrders, service) => {
  let allOrders = {
    customers: [],
    orders: [],
    confirmed: 0,
    edited: 0,
    complete: 0,
    incomplete: 0,
    meals: 0,
    juices: 0,
    items: 0,
    types: {},
    weight: 0,
  };

  let spOrders = {};

  provisionedOrders.data.forEach((sp) => {
    let spName = Object.keys(sp)[0];
    let parsedOrders = sp[spName].map((order) => {
      return parseOrder(order, spName, service);
    });
    if (!spOrders[spName] && sp[spName].length) {
      spOrders[spName] = {};
    }
    spOrders[spName] = parseSpOrders(parsedOrders, spName, service);
    /**
     * Update stats for All Orders
     */
    const {
      confirmed,
      edited,
      complete,
      incomplete,
      customers,
      orders,
      meals,
      juices,
      items,
      types,
      weight,
    } = spOrders[spName];
    allOrders.confirmed = allOrders.confirmed + confirmed;
    allOrders.edited = allOrders.edited + edited;
    allOrders.complete = allOrders.complete + complete;
    allOrders.incomplete = allOrders.incomplete + incomplete;
    allOrders.customers = allOrders.customers.concat(customers);
    allOrders.orders = allOrders.orders.concat(orders);
    allOrders.meals = allOrders.meals + (meals || 0);
    allOrders.juices = allOrders.juices + (juices || 0);
    allOrders.items = allOrders.items + (items || 0);

    if (service === "laundry") {
      allOrders.weight = allOrders.weight + weight;
      let laundryTypes = Object.keys(types);
      laundryTypes.forEach((type) => {
        if (!allOrders.types[type]) {
          allOrders.types[type] = {
            total: 0,
            items: 0,
            bags: 0,
          };
        }
        allOrders.types[type].total =
          allOrders.types[type].total + types[type].total;
        allOrders.types[type].items =
          allOrders.types[type].items + types[type].items;
        allOrders.types[type].bags =
          allOrders.types[type].bags + types[type].bags;
      });
    }
  });

  allOrders.customers = [...new Set(allOrders.customers)];

  return {
    all: allOrders,
    ...spOrders,
  };
};
