<template>
  <div>
    <el-table
      v-loading="loading"
      :data="data.data"
      @select-all="setSelections"
      @selection-change="setSelections"
    >
      <el-table-column
        v-if="allowConfirm"
        type="selection"
        width="45"
      ></el-table-column>
      <el-table-column width="80">
        <template slot="header">
          <div class="table--header">
            <span>Order Id</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'orders.order',
              params: { id: scope.row.id },
            }"
          >
            <span class="font-sm text-primary">
              {{ scope.row.id }}</span
            ></router-link
          >
        </template>
      </el-table-column>
      <el-table-column :width="200">
        <template slot="header">
          <div class="table--header">
            <span>Customer</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm"> {{ formatName(scope.row.customer) }}</span>
          <span class="font-xsm text-grey-tertiary">
            {{ scope.row.gardener }}</span
          >
        </template>
      </el-table-column>
      <el-table-column v-if="service === 'beauty'" width="200">
        <template slot="header">
          <div class="table--header">
            <span>Variations</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <ul class="meals">
            <li v-for="(variation, i) in scope.row.variations" :key="i">
              <p>{{ formatToTitleCase(variation.name) }}</p>
              <span class="text-grey-tertiary">{{
                formatToTitleCase(variation.addons)
              }}</span>
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column v-else-if="service === 'cleaning'" width="200">
        <template slot="header">
          <div class="table--header">
            <span>Type</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm"> {{ formatToTitleCase(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <template v-else-if="service === 'laundry'">
        <el-table-column width="120">
          <template slot="header">
            <div class="table--header">
              <span>Type</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatToTitleCase(scope.row.type) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot="header">
            <div class="table--header">
              <span>Quantity</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">{{
              formatServiceUnit("laundry", scope.row.bags, true)
            }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column v-else-if="service === 'meal'">
        <template slot="header">
          <div class="table--header">
            <span>Meals</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <ul class="meals">
            <li v-for="(meal, i) in scope.row.meals" :key="i">
              {{ formatMealName(meal) }}
            </li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column
        width="240"
        v-if="service !== 'meal' && allowAccessForFeature('develop', ['admin'])"
      >
        <template slot="header">
          <div class="table--header">
            <span>Preference note</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            <span :ref="'preference_note' + scope.$index">
              <span :ref="'text' + scope.$index" class="preference">{{
                scope.row.preference
              }}</span>
              <span v-if="scope.row.preference.length > 47">
                <span
                  class="d-none"
                  :ref="'button-collapse' + scope.$index"
                  @click="toggleText(scope.$index, 'collapse')"
                  >Collapse</span
                >
                <span
                  :ref="'button-expand' + scope.$index"
                  type="text"
                  @click="toggleText(scope.$index, 'expand')"
                  class="toggle-button"
                  >Expand</span
                >
              </span>
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column width="220">
        <template slot="header">
          <div class="table--header">
            <span>Service Partner</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ scope.row.sp }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="140">
        <template slot="header">
          <div class="table--header">
            <span>Status</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <eden-tag :value="scope.row.confirmed ? 'confirmed' : 'edited'" />
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Menu Type</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.menu_type?.toLowerCase().includes('corporate standard')
            "
          >
            Corporate Standard
          </div>
          <div
            v-else-if="
              scope.row.menu_type?.toLowerCase().includes('individual standard')
            "
          >
            Individual Standard
          </div>
          <div v-else-if="scope.row.menu_type?.toLowerCase().includes('lite')">
            Lite
          </div>
          <div v-else-if="scope.row.menu_type?.toLowerCase().includes('basic')">
            Basic
          </div>
        </template>
      </el-table-column>
      <el-table-column width="140">
        <template slot="header">
          <div class="table--header">
            <span>Order Type</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <eden-tag
            :value="scope.row.is_one_time_order ? 'One-time' : 'Subscription'"
          />
        </template>
      </el-table-column>
      <el-table-column v-if="service === 'laundry'" width="140">
        <template slot="header">
          <div class="table--header">
            <span>Pickup Date</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ formatDate(scope.row.pickup, "mo d, y") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="140">
        <template slot="header">
          <div class="table--header">
            <span>{{ deliveryColumn }}</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ formatDate(scope.row.delivery, "m do, y") }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <eden-pagination
      v-if="data.data && data.data.length"
      :from="from"
      :to="to"
      :total="total"
      :current-page.sync="page"
    />
  </div>
</template>

<script>
export default {
  name: "AllOrdersView",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    service: {
      type: String,
      default: "",
    },
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedOrders: {
      type: Array,
      default() {
        return [];
      },
    },
    period: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 50,
    };
  },
  computed: {
    paginateData() {
      if (!this.orders.length) {
        return [];
      }
      let periods = ["today", "yesterday", "tomorrow", "nexttwodays"];
      let sorted;
      periods.includes(this.period)
        ? (sorted = this.sortArray({
            data: this.orders,
            property: "customer",
          }))
        : (sorted = this.sortArray({
            data: this.orders,
            property: "delivery",
          }));
      return this.paginate(sorted, this.pageSize);
    },
    data() {
      return this.paginateData[this.page] || [];
    },
    from() {
      return this.data.from;
    },
    to() {
      return this.data.to;
    },
    total() {
      return this.orders.length;
    },
    allowConfirm() {
      let periods = ["lastweek", "yesterday", "custom"];
      return !periods.includes(this.period);
    },
    setSelectedOrders: {
      get() {
        return this.selectedOrders;
      },
      set(value) {
        this.$emit("update:selectedOrders", value);
      },
    },
    deliveryColumn() {
      switch (this.service) {
        case "beauty":
          return "SP Arrival Date";
        case "cleaning":
          return "Cleaning Date";
        case "laundry":
          return "Delivery Date";
        case "meal":
          return "Delivery Date";
        default:
          return "Delivery Date";
      }
    },
  },
  methods: {
    toggleText(index, action) {
      if (action === "expand") {
        this.$refs["text" + index].classList.value = "";
        this.$refs["button-expand" + index].classList.value = "d-none";
        this.$refs["button-collapse" + index].classList.value = "toggle-button";
      } else {
        this.$refs["text" + index].classList.value = "preference";
        this.$refs["button-collapse" + index].classList.value = "d-none";
        this.$refs["button-expand" + index].classList.value = "toggle-button";
      }
    },
    setSelections(orders) {
      this.$emit("confirm", {
        type: "some",
        ids: orders.map((order) => order.id),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.meals {
  margin: 0;
  padding-left: 10px;

  li {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
.d-none {
  display: none;
}
.toggle-button {
  margin-left: 0px !important;
  padding-top: 0px !important;
  cursor: pointer;
  color: var(--eden-green-primary) !important;
  display: block;
}
.preference {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
